<template>
    <div>
      <b-card no-body class="mb-0 p-2">
        <div class="profile-image p-0 d-flex justify-content-center">
            <b-avatar
              rounded="none"
              size="114"
              variant="light"
              :src="customerData.profilephoto"
              style="
                width: 114px;
                height: 114px;
                object-fit: cover;
                border-radius: 10px;
              "
            />
          </div>
          <hr class="mb-2" />
          <!-- follower projects rank -->
          <h4 class="text-muted">Client Details</h4>
          <div
            v-if="isLoading"
            style="height: 400px"
            class="d-flex justify-content-center align-items-center"
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div v-else>
            <b-col cols="12" class="pt-1 d-flex">
              <!-- {{ data }} -->
              <h6 class="lableTitle">Username:</h6>
              <p class="ml-1 mb-0 inputValue">{{ customerData.f_name }}</p>
            </b-col>

            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Email:</h6>
              <p class="ml-1 inputValue mb-0">
                {{ customerData.email }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Gender:</h6>
              <p class="mb-0">
                <b-badge class="ml-1" variant="light-success">{{
                  customerData.gender ? customerData.gender.name : ""
                }}</b-badge>
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="font-weight-bolder inputValue">Date of Birth :</h6>
              <p class="ml-1 inputValue mb-0">
                {{ customerData.dob?moment(customerData.dob).format('DD/MM/YYYY'):'' }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Injuries:</h6>
              <p class="inputValue ml-1 mb-0">
                {{ customerData.injuries }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Country:</h6>
              <p class="inputValue ml-1 mb-0">
                {{ customerData.country ? customerData.country.name : "" }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Height :</h6>
              <p class="inputValue ml-1 mb-0">
                {{ customerData.height }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Goal :</h6>
              <p class="inputValue ml-1 mb-0">
                {{
                  customerData &&
                  customerData.customer_goal && customerData.customer_goal.goal
                    ? customerData.customer_goal.goal.name
                    : ""
                }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Current weight:</h6>
              <p class="inputValue ml-1 mb-0">
                {{
                  customerData &&
                  customerData.customer_goal 
                    ? customerData.customer_goal.current_weight
                    : ""
                }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Goal weight:</h6>
              <p class="inputValue ml-1 mb-0">
                {{
                  customerData &&
                  customerData.customer_goal  
                    ? customerData.customer_goal.goal_weight
                    : ""
                }}
              </p>
            </b-col>

            <b-col cols="12" class="pt-1 d-flex" v-if="
             customerData &&
                  customerData.customer_goal && (customerData.customer_goal.weeklygainweight || customerData.customer_goal.weeklyloseweight)">
              <h6 class="inputValue font-weight-bolder">Weekly goal:</h6>
              <p class="inputValue ml-1 mb-0">
                {{(customerData.customer_goal.weeklygainweight?customerData.customer_goal.weeklygainweight.name
                    : (customerData.customer_goal.weeklyloseweight ?customerData.customer_goal.weeklyloseweight.name:'' ))
                }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-1 d-flex">
              <h6 class="inputValue font-weight-bolder">Contact :</h6>
              <p class="inputValue ml-1 mb-0">
                {{ customerData.phone }}
              </p>
            </b-col>
             <!-- <b-col cols="12" class="d-flex pt-1">
              <h6
                class="inputValue font-weight-bolder"
              >
                Period of access: 
              </h6>
              <p class="ml-1 mb-0 button">
              <span>
                {{customerData.plans ? customerData.plans.name :'' }}
              </span>
              </p>
            </b-col> -->
          </div>
      </b-card>
    </div>
  </template>
  
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BSpinner
  } from "bootstrap-vue";
  import axios from "@/component/axios";
  import moment from "moment";

  
  export default {
    components: {
      BCard,
      BRow,
      moment,
      BCol,
      BButton,
      BAvatar,
      BLink,
      BBadge,
      BSpinner
    },
    data() {
      return {
        moment,
        accessToken: localStorage.getItem("accessToken"),
        baseApi: process.env.VUE_APP_APIENDPOINT,
        customerData:{},
        isLoading:false
      };
    },
    props:['selectedId'],
    watch: {
    selectedId() {
        this.getData();
      },
    },
    mounted() {
      this.getData();
    },
    methods: {
        async getData(){
            this.isLoading =true
            await axios({
                method: "GET",
                url: `${this.baseApi}customer/show/${this.selectedId}`,
                headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
                },
            })
            .then((res)=>{
                this.customerData = res.data.data.user
            })
            .finally(()=>{
                this.isLoading =false
            })
        }
    }
  };
  </script>
  