<template>
    <div>
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row class="d-flex justify-content-between w-100">
            <!-- Per Page -->
            <b-col md="6" class="d-flex mt-1">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="per_page"
                class="per-page-selector d-inline-block mx-50"
                :options="['5', '10', '20', '50', '100']"
                @input="changePagination()"
              />
            </b-col>
  
            <b-col md="6" class="d-flex mt-1 ml-100">
              <div class="w-100 d-flex justify-content-end">
                <div v-if="searchTerm.type == 'dropdown'">
                  <v-select
                    style="width: 200px"
                    placeholder="Search..."
                    :options="getOptions(searchTerm.storename)"
                    :label="searchTerm.displayName"
                    class="d-inline-block mr-1"
                    v-model="search"
                    @input="handleSearch()"
                  />
                </div>
                <div v-else>
                  <b-form-input
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                    v-model="search"
                    @input="handleSearch()"
                  />
                </div>
                <div class="ml-1">
                  <v-select
                    style="width: 150px !important"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :searchable="false"
                    v-model="searchTerm"
                    placeholder="Select"
                    class="per-page-selector d-inline-block mx-50"
                    label="name"
                    tbody-tr-class="custom-table-row"
                    :options="searchTermOptions"
                    @input="handleSearchType()"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          :busy="isLoading"
          ref="refUserListTable"
          class="position-relative"
          :items="tabledata"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          thead-class="head"
          @sort-changed="onSortChanged"
          >
          <template #table-busy>
            <div class="text-center text-secondary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(id)="data">
            <span class="text-primary font-weight-bold" style="font-size: 15px">
              {{ data.index + 1 }}
            </span>
          </template>
          <template #cell(f_name)="data" >
            <div class="font-weight-bolder cursor-pointer w-100 d-flex align-items-center" @dblclick="openProfile(data.item)">
              <span class="mr-1">
                <b-avatar
                  size="32"
                  :src="data.item.profilephoto"
                  :text="data.item.f_name.substring(0, 1)"
                />
              </span>
              {{ data.item.f_name }}
            </div>
          </template>
          <template #cell(goal)="data">
            <div class="d-flex w-100 align-items-center">
              <p class="lableTitle">
                {{ data.item.goal_name}}
                <!-- {{  }}
                {{
                  data.item &&
                  data.item.customer_goal &&
                  data.item.customer_goal.length > 0
                    ? data.item.customer_goal[0].goal.name
                    : ""
                }} -->
                <!-- {{ 
                  data.item.goal? data.item.goal.name:''
                 }} -->
              </p>
            </div>
          </template>
          <template #cell(phone)="data">
            <div class="d-flex w-100 align-items-center">
              <p class="lableTitle">{{ data.item.phone }}</p>
            </div>
          </template>
          <template #cell(Actions)="data">
            <div class="d-flex">
              <feather-icon
                icon="EyeIcon"
                size="22"
                @click="handleView(data.item.id)"
                class="cursor-pointer mr-1"
              />

              <feather-icon
                icon="Trash2Icon"
                size="22"
                @click="removeRow(data.item.id)"
                class="cursor-pointer mr-1"
              />
            </div>
          </template>
        </b-table>
        <div class="w-100 d-flex justify-content-between p-1">
          <!-- Pagination -->
          <div>
            <span class="text-nowrap">
              Showing {{ paginationStart }} to {{ paginationEnd }} of
              {{ paginationTotal }}</span
            >
          </div>
          <b-pagination
            :total-rows="paginationTotal"
            :per-page="per_page"
            v-model="paginationValue"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="changePagination()"
          >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
          </b-pagination>
          <b-modal
          v-model="customerView"
          no-close-on-backdrop
          hide-footer
          centered
          size="md">
          <customer-view :selectedId="selectedId"/>
          </b-modal>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BAvatar,
    BPagination,
    BSpinner,
    BFormInput,
    BModal
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import axios from "@/component/axios";
  import moment from "moment";
  import _ from "lodash";
import customerView from '../../../dashboard/RSPT/components/CustomerView.vue'

  
  export default {
    components: {
        BCard,
        BRow,
        BCol,
        BButton,
        BTable,
        BAvatar,
        BPagination,
        BSpinner,
        moment,
        vSelect,  
        BFormInput,
        BModal,
        customerView
    },
    data() {
      return {
        moment,
        columnFilters: {},
        tableColumns: [
          {
            key: "id",
            label: "NO",
          },
          { key: "f_name", label: "Client", sortable: true },
          {
            key: "goal",
            label: "GOAL",
            type: "dropdown",
            displayName: "name",
          },
          {
            key: "phone",
            label: "Contact",
            sortable: true,
            
          },
          // { key: 'balance', sortable: true },
          { key: "Actions", label: "Actions" },
        ],
        searchTerm: { name: "Client", value: "customer" },
        newTerm: { name: "Trainer", value: "trainer" },
        newSearch: "",
        search: "",
        tabledata: [],
        accessToken: localStorage.getItem("accessToken"),
        baseApi: process.env.VUE_APP_APIENDPOINT,
        isLoading: false,
        sortBy: "",
        orderBy: true ? "ASC" : "DESC",
        ifEdit: false,
        paginationStart: 0,
        paginationEnd: 0,
        paginationValue: 1,
        paginationTotal: 0,
        per_page: 10,
        params: {},
        searchTermOptions: [
          { name: "Client", value: "customer" },
          {
            name: "Goal",
            value: "goal",
            type: "dropdown",
            displayName: "name",
            storename: "goal",
          },
        ],
        searchOptions: [],
        storeTainer:[],
        selectedId:0,
        customerView:false
      };
    },
    mounted() {
      this.getClientData();
    },
    methods: {
        handleView(id){
            this.selectedId = id
            this.customerView = !this.customerView 
        },
      onSortChanged(e) {
        this.sortBy = e.sortBy;
        this.orderBy = e.sortDesc ? "DESC" : "ASC";
        // console.log(this.sortBy)
        // console.log(this.orderBy)
        this.getClientData();
      },
      handleSearchType() {
        this.search = "";
        this.getClientData();
      },
      changePagination() {
        this.getClientData();
      },
      changeSearch() {
        if (this.search) {
          this.getClientData();
        }
      },
      async getClientData() {
        this.isLoading = true;
        let params = {};
        params.page = this.paginationValue;
        params.per_page = this.per_page;
        if (this.search) {
          params.search = this.search.id ? this.search.id : this.search;
          params.type = this.searchTerm.value;
        }
        if (this.sortBy) {
          params.sortBy = this.sortBy;
          params.orderBy = this.orderBy;
        }
        await axios({
          method: "GET",
          url: `${this.baseApi}customer/get-rejected-customer`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          params: params,
        })
          .then((json) => {
            this.tabledata = json?.data.data.data;
            this.paginationTotal = json?.data.data.total;
            this.paginationStart = json?.data.data.from;
            this.paginationEnd = json?.data.data.to;
            this.isLoading = false;
  
            this.tabledata.map((item)=>{
              item.goal_name = item.customer_goal&& item.customer_goal.goal?item.customer_goal.goal.name:'' 
            })
  
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error, "error");
          });
      },
  
      handleSearch: _.debounce(function () {
        this.getClientData();
        // this.getallTrainer();
      }, 1000),
      async removeRow(id) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then(async (result) => {
            if (result.value) {
              await axios({
                method: "Delete",
                url: `${this.baseApi}customer/delete/${id}`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.accessToken}`,
                },
              })
                .then(() => {
                  const index = this.tabledata.findIndex(
                    (item) => item.id === id
                  );
                  if (index !== -1) {
                    this.tabledata.splice(index, 1);
                    this.$swal({
                      title: "Deleted",
                      text: "Client deleted successfully",
                      icon: "success",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: false,
                    });
                    this.getClientData();
                    this.$forceUpdate();
                  }
                })
                .catch((error) => {
                  console.error("Error deleting user:", error);
                });
            }
          })
          .catch((error) => console.log(error, "error"))
          .finally(() => {});
      },
  
      openProfile(item) {
        const userId = item.id;
        this.$router.push({ path: `/clientmanagement/${userId}` });
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .head {
    width: 100px;
  }
  .lableTitle {
    font-size: 15px;
    font-weight: 500;
    font-family: "Public Sans", sans-serif;
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  .trainerList{
    max-height: 500px;
    overflow: auto;
  }
  </style>